import logo from "../img/M&M.png"


function Home() {

    return (
        <div id="background-style-home">
            <div>
                <img src={logo} alt="" className='headerimg img-fluid' />
            </div>
            <div>
                <a href="https://melodyshipley.kw.com/" className="buttons">Realestate</a>
            </div>
            <div>
                <a href="/health" className="buttons">Health & Wellness</a>
            </div>
            <div>
                <a href="/ministry" className="buttons">Ministries</a>
            </div>
        </div>
        
    )
}

export default Home