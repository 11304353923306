

function Health() {

    return(
        <div id="background-style-home">
            <div>
                <a href="https://plexusworldwide.com/melodyshipley" className="buttons">Plexus</a>
            </div>
            <div>
                <a href="https://www.lifewave.com/2106148" className="buttons">Lifewave</a>
            </div>
        </div>
    )
}

export default Health